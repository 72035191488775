import React from 'react'
import availableLangs from '@localization/availableLangs'
import Report0520PageContainer from '@containers/ReportPagesContainers/Report0520PageContainer'
import SeoContainer from '@containers/SeoContainer'
import report0520FromScript from '../../externalScriptsCode/report0520FromScript'

const IndexPage = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.de} pagePath='report0520'>
        <script async='true' dangerouslySetInnerHTML={{ __html: report0520FromScript }} />
      </SeoContainer>

      <Report0520PageContainer />
    </>
  )
}

export default IndexPage
