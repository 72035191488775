import React from 'react'
import CommonReportContainer from './CommonReportContainer'
import Report0520Form from '@common/forms/Report0520Form'

const Report0520PageContainer = () => {
  return (
    <CommonReportContainer
      FormComponent={Report0520Form}
      reportImageName='report_may.png'
    />
  )
}

export default Report0520PageContainer
