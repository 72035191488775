import React from 'react'
import styled from 'styled-components'
import { TransparentButton } from '@common/buttons'

import { FormWrapper } from './reportFormStyles'

const Report0520Form = () => {
  return (
    <FormWrapper>
      <form
        method='POST'
        action='https://onlinepunks.activehosted.com/proc.php'
        id='_form_39_'
        className='_form _form_39 _inline-form  _dark'
        noValidate={false}
      >
        <input type='hidden' name='u' value='39' />
        <input type='hidden' name='f' value='39' />
        <input type='hidden' name='s' />
        <input type='hidden' name='c' value='0' />
        <input type='hidden' name='m' value='0' />
        <input type='hidden' name='act' value='sub' />
        <input type='hidden' name='v' value='2' />
        <div className='_form-content'>
          <div className='_form_element _x53666083 _full_width _clear'>
            <div className='_form-title'>
              Der influData TikTok Statistik-Report 2020
            </div>
          </div>
          <div className='_form_element _x77596755 _full_width _clear'>
            <div className='_html-code'>
              <h3>
                Statistische Analyse und Top-Listen deutschsprachiger TikTok
                Creator im weltweiten Vergleich.
              </h3>
            </div>
          </div>
          <div className='_form_element _x45994927 _full_width _clear'>
            <div className='_html-code'>
              <span>
                Kostenlose 25 Seiten statistische Datenauswertung über die
                deutschsprachige und weltweite Creator-Community. Wer sind die
                größten Creator? Wie oft wird im Schnitt gepostet oder welche
                Hashtags gehen gerade viral? Alle Zahlen und Daten, die du über
                TikTok wissen willst, findest du in diesem Report. Nach dem
                Absenden bekommst du den Download-Link in einigen Minuten via
                Mail zugeschickt.
              </span>
            </div>
          </div>
          <div className='_form_element _x72983913 _full_width '>
            <label className='_form-label'>Vorname & Nachname</label>
            <div className='_field-wrapper'>
              <input
                type='text'
                name='firstname'
                // placeholder='Vorname / Nachname'
                required
              />
            </div>
          </div>
          <div className='_form_element _x02541768 _full_width '>
            <label className='_form-label'>E-mail</label>
            <div className='_field-wrapper'>
              <input
                type='text'
                name='email'
                // placeholder='Deine Mail'
                required
              />
            </div>
          </div>
          <div className='_button-wrapper _full_width'>
            <TransparentButton
              id='_form_39_submit'
              className='_submit'
              type='submit'
            >
              Absenden
            </TransparentButton>
          </div>
          <br />
          <small>
            Mit Klicken des Buttons “Absenden” stimmen Sie zu, dass wir Ihre
            Daten gemäß unseren Datenschutzbestimmungen speichern und
            verarbeiten.
          </small>
          <div className='_clear-element'></div>
        </div>
        <div className='_form-thank-you' style={{ display: 'none' }}></div>
      </form>
    </FormWrapper>
  )
}

export default Report0520Form
